import axios from 'axios';
import {
  TRMLOGINPERSONA,
  TRMFORGOTPASS,
  TRMRESET,
  TRMCREATEPERSONA,
  TRMEDITPERSONA,
  TRMGETPERSONABYID
} from '../../constantes'

export default {
  namespaced: true,
  state: {
    respuestaLogin: {},
    respuestaForgot: {},
    tokenRecuperacion: null,
    resTokenRecuperacion: {},

    personaCargada:{},
    resultCreatePersona:{}
  },
  mutations: {
    setResLogin(state, payload) {
      state.respuestaLogin = payload
    },
    setResForgotPass(state, payload) {
      state.respuestaForgot = payload
    },
    setTokenRecuperacion(state, payload) {
      state.tokenRecuperacion = payload
    },
    setResTokenRecuperacion(state, payload) {
      state.resTokenRecuperacion = payload
    },
    setResultCreatePersona(state, payload){
      state.resultCreatePersona = payload
    },
    setPersonaCargada(state, payload){
      state.personaCargada = payload
    },
  },
  actions: {
    async login({ commit }, login) {
      return axios.post(TRMLOGINPERSONA, login)
        .then(res => {
          console.log(res)
          commit('setResLogin', res.data)
        })
        .catch(error => { return Promise.reject(error.response) })
    },

    async forgotPass({ commit }, mail) {
      return axios.post(TRMFORGOTPASS, mail)
        .then(res => {
          console.log(res)
          commit('setResForgotPass', res.data)
        })
        .catch(error => { return Promise.reject(error.response) })
    },

    setTokenRecueracion({ commit }, token) {
      commit('setTokenRecuperacion', token)
    },

    async resetPass({ commit }, datos) {
      return axios.post(TRMRESET, datos)
        .then(res => {
          console.log(res)
          commit('setResTokenRecuperacion', res.data)
        })
        .catch(error => { return Promise.reject(error.response) })
    },

    async getPersonaById({ commit }, idPersona) {
      return axios.put(TRMGETPERSONABYID + idPersona)
        .then(res => {
          console.log(res)
          commit('setPersonaCargada', res.data.data[0])
        })
        .catch(error => { return Promise.reject(error.response) })
    },

    async createPersona({ commit }, persona) {
      return axios.post(TRMCREATEPERSONA, persona)
        .then(res => {
          console.log(res)
          commit('setResultCreatePersona', res)
        })
        .catch(error => { return Promise.reject(error.response) })
    },
    
    async editPersona({ commit }, persona) {
      return axios.post(TRMEDITPERSONA, persona)
        .then(res => {
          console.log(res)
          commit('setResultCreatePersona', res)
        })
        .catch(error => { return Promise.reject(error.response) })
    },
  },
}