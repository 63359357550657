import axios from 'axios';
import {
  TRMCREATEANIMALPERSONA,
  TRMGETANIMALPERSONABYIDANIMAL
} from '../../constantes';

export default {
  namespaced: true,
  state: {
    resultCreateAnimalPersona: {},
    animalPersonaCargado: {}
  },
  mutations: {
    setResultCreateAnimalPersona(state, payload) {
      state.resultCreateAnimalPersona = payload
    },
    setAnimalPersonaCargado(state, payload) {
      state.animalPersonaCargado = payload
    },
  },
  actions: {
    async crearAnimalPersona({ commit }, animal) {
      return axios.post(TRMCREATEANIMALPERSONA, animal)
        .then(results => {
          commit('setResultCreateAnimalPersona', results)
        })
        .catch(error => { return Promise.reject(error.response) })
    },

    async getAnimalPersonaByIdAnimal({ commit }, animal) {
      return axios.put(TRMGETANIMALPERSONABYIDANIMAL + animal)
        .then(results => {
          commit('setAnimalPersonaCargado', results.data.data)
        })
        .catch(error => { return Promise.reject(error.response) })
    },

  }
}