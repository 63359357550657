import axios from 'axios';
import {
    TRMGETANIMALBYPARAMS,
    TRMCREATEANIMAL,
    TRMEDITANIMAL,
    TRMGETANIMALBYID,
    TRMGETRAZASBYTIPOANIMAL,
    TRMGETTIPOANIMALES,
    TRMGETATENCIONVETERINARIABYIDANIMAL,
    TRMCREATEATENCIONVETERINARIA,
    TRMEDITATENCIONVETERINARIA,
    TRMGETPROCEDIMIENTOSBYIDATENCIONVETERINARIA,
    TRMCREATEATENCIONPROCEDIMIENTO,
    TRMEDITATENCIONPROCEDIMIENTO,
    TRMCREATEAUTORIZACIONEUTANASIA,
    TRMEDITAUTORIZACIONEUTANASIA,
    TRMGETAUTORIZACIONEUTANASIABYID,
    TRMGETPROCEDIMIENTOS,
    TRMGETSOLICITUDADOPCIONBYPARAMS,
    TRMGETSOLICITUDADOPCIONBYID,
    TRMEDITSOLICITUDADOPCION,
    TRMEDITCREATESOLICITUDADOPCION
} from '../../constantes';

export default {
    namespaced: true,
    state: {
        animalesServicio: [],
        animalSeleccionado: {},
        animalCargado: {},
        resultCreateAnimal: {},
        tiposAnimales: [],
        tiposRazas: [],
        atencionesVeterinariasServicio: [],
        resultCreateAtencionVeterinaria: {},
        atencionVeterinariaSeleccionada: {},
        procedimientosServicio: [],
        resultCreateProcedimiento: {},

        autorizacionProcedimientoCargado: {},
        resultCreateAutorizacionProcedimiento: {},
        tiposProcedimientosServicio: [],

        solicitudesAdopcionServicio: [],
        resultCreateSolicitudAdopcion: {},
        solicitudAdopcionCargada: {},
        solicitudesAdopcionSeleccionada: {}
    },
    mutations: {
        setAnimals(state, payload) {
            state.animalesServicio = payload
        },
        setAnimalSeleccionado(state, payload) {
            state.animalSeleccionado = payload
        },
        setAnimalCargado(state, payload) {
            state.animalCargado = payload
        },
        setTiposAnimales(state, payload) {
            state.tiposAnimales = payload
        },
        setTiposRazas(state, payload) {
            state.tiposRazas = payload
        },
        setAtencionesVeterinarias(state, payload) {
            state.atencionesVeterinariasServicio = payload
        },
        setAtencionVeterinariaSeleccionada(state, payload){
            state.atencionVeterinariaSeleccionada = payload
        },
        setProcedimientos(state, payload) {
            state.procedimientosServicio = payload
        },
        setResultCreateAnimal(state, payload) {
            state.resultCreateAnimal = payload
        },
        setResultCreateAtencionVeterinaria(state, payload) {
            state.resultCreateAtencionVeterinaria = payload
        },
        setResultCreateProcedimiento(state, payload) {
            state.resultCreateProcedimiento = payload
        },
        setResultCreateAutorizacionProcedimiento(state, payload) {
            state.resultCreateAutorizacionProcedimiento = payload
        },
        setAutorizacionProcedimientoCargado(state, payload){
            state.autorizacionProcedimientoCargado = payload
        },
        setTiposProcedimientosServicio(state, payload){
            state.tiposProcedimientosServicio = payload
        },
        setSolicitudesAdopcion(state, payload) {
            state.solicitudesAdopcionServicio = payload
        },
        setResulCreatetSolicitudAdopcion(state, payload){
            state.resultCreateSolicitudAdopcion = payload
        },
        setSolicitudesAdopcionSeleccionada(state, payload) {
            state.solicitudesAdopcionSeleccionada = payload
        },
        setSolicitudAdopcionCargada(state, payload) {
            state.solicitudAdopcionCargada = payload
        },
    },
    actions: {
        async buscarAnimales({ commit }, filtros) {
            return axios.post(TRMGETANIMALBYPARAMS, filtros)
                .then(results => {
                    commit('setAnimals', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        seleccionarAnimal({ commit }, animal) {
            commit('setAnimalSeleccionado', animal)
        },

        resetAnimalSeleccionado({ commit }) {
            commit('setAnimalSeleccionado', {})
        },

        resetAnimalesServicio({ commit }) {
            commit('setAnimals', [])
        },

        async crearAnimal({ commit }, animal) {
            return axios.post(TRMCREATEANIMAL, animal)
                .then(results => {
                    commit('setResultCreateAnimal', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async editarAnimal({ commit }, animal) {
            return axios.post(TRMEDITANIMAL, animal)
                .then(results => {
                    commit('setResultCreateAnimal', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async cargarAnimalById({ commit }, id) {
            return axios.put(TRMGETANIMALBYID + id)
                .then(results => {
                    commit('setAnimalCargado', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async cargarTiposAnimales({ commit }) {
            return axios.get(TRMGETTIPOANIMALES)
                .then(results => {
                    commit('setTiposAnimales', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async cargarTiposRazasByTipoAnimal({ commit }, idTipoAnimal) {
            return axios.put(TRMGETRAZASBYTIPOANIMAL + idTipoAnimal)
                .then(results => {
                    commit('setTiposRazas', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        resetTipoRaza({ commit }) {
            commit('setTiposRazas', [])
        },

        async crearAtencionVeterinaria({ commit }, atencionVeterinaria) {
            return axios.post(TRMCREATEATENCIONVETERINARIA, atencionVeterinaria)
                .then(results => {
                    commit('setResultCreateAtencionVeterinaria', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },
        
        async editarAtencionVeterinaria({ commit }, atencionVeterinaria) {
            return axios.post(TRMEDITATENCIONVETERINARIA, atencionVeterinaria)
                .then(results => {
                    commit('setResultCreateAtencionVeterinaria', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async cargarAtencionesVeterinariasByIdAnimal({ commit }, idAnimal) {
            return axios.put(TRMGETATENCIONVETERINARIABYIDANIMAL + idAnimal)
                .then(results => {
                    commit('setAtencionesVeterinarias', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        resetAtencionesVeterinariasTabla({ commit }) {
            commit('setAtencionesVeterinarias', [])
        },
        
        seleccionarAtencionVeterinaria({ commit }, atencionVet) {
            commit('setAtencionVeterinariaSeleccionada', atencionVet)
        },

        resetAtencionVeterinaria({ commit }) {
            commit('setAtencionVeterinariaSeleccionada', {})
        },

        async cargarProcedimientosByIdAtencionVet({ commit }, id) {
            return axios.put(TRMGETPROCEDIMIENTOSBYIDATENCIONVETERINARIA + id)
                .then(results => {
                    commit('setProcedimientos', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async crearProcedimiento({ commit }, procedimiento) {
            return axios.post(TRMCREATEATENCIONPROCEDIMIENTO, procedimiento)
                .then(results => {
                    commit('setResultCreateProcedimiento', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async editarProcedimiento({ commit }, procedimiento) {
            return axios.post(TRMEDITATENCIONPROCEDIMIENTO, procedimiento)
                .then(results => {
                    commit('setResultCreateProcedimiento', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async crearAutorizacionProcedimiento({ commit }, autorizacionProced) {
            return axios.post(TRMCREATEAUTORIZACIONEUTANASIA, autorizacionProced)
                .then(results => {
                    commit('setResultCreateAutorizacionProcedimiento', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async editarAutorizacionProcedimiento({ commit }, autorizacionProced) {
            return axios.post(TRMEDITAUTORIZACIONEUTANASIA, autorizacionProced)
                .then(results => {
                    commit('setResultCreateAutorizacionProcedimiento', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async cargarAutorizacionProcedimientoById({ commit }, id) {
            return axios.put(TRMGETAUTORIZACIONEUTANASIABYID + id)
                .then(results => {
                    commit('setAutorizacionProcedimientoCargado', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async cargarTiposProcedimientoByDescription({ commit }, description) {
            return axios.put(TRMGETPROCEDIMIENTOS + description)
                .then(results => {
                    commit('setTiposProcedimientosServicio', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async crearSolicitudAdopcion({ commit }, solicitudAdop) {
            return axios.post(TRMEDITCREATESOLICITUDADOPCION, solicitudAdop)
                .then(results => {
                    commit('setResulCreatetSolicitudAdopcion', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async editarSolicitudAdopcion({ commit }, solicitudAdop) {
            return axios.post(TRMEDITSOLICITUDADOPCION, solicitudAdop)
                .then(results => {
                    commit('setResulCreatetSolicitudAdopcion', results)
                })
                .catch(error => { return Promise.reject(error.response) })
        },

        async buscarSolicitudesAdopcion({ commit }, filtros) {
            return axios.post(TRMGETSOLICITUDADOPCIONBYPARAMS, filtros)
                .then(results => {
                    commit('setSolicitudesAdopcion', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async cargarSolicitudAdopcionById({ commit }, id) {
            return axios.put(TRMGETSOLICITUDADOPCIONBYID + id)
                .then(results => {
                    commit('setSolicitudAdopcionCargada', results.data.data)
                })
                .catch(error => {
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        seleccionarSolicitudAdopcion({ commit }, animal) {
            commit('setSolicitudesAdopcionSeleccionada', animal)
        },

    }
}