// const api= 'http://23.102.132.196:4000'
const api= '/api'

const ESTADOS_VISITAS = [
    {
        estado: 0,
        nombre: 'Planeada'
    },
    {
        estado: 1,
        nombre: 'En ejecución'
    },
    {
        estado: 2,
        nombre: 'Ejecutada'
    },
]

export const traerNombreEstado = (estado)=>{
    return ESTADOS_VISITAS.find(item=>{
        if(item.estado == estado){
            return item.nombre
        }
    })
}

const ESTADOS_ADOPCION = [
    {
        estado: 0,
        nombre: 'Rechazada'
    },
    {
        estado: 1,
        nombre: 'Aprobada'
    },
    {
        estado: 2,
        nombre: 'Pendiente'
    },
]


export const traerNombreEstadoAdopcion = (estado)=>{
    return ESTADOS_ADOPCION.find(item=>{
        if(item.estado == estado){
            return item.nombre
        }
    })
}

export const TOKEN_SECRET = 'TKN_SC_WORD'

// URLS LICENCIAS
export const RCDGETLICENCIABYNUMACTO = `${api}/rcdGetLicenciaByNumacto/`
export const RCDCREATELICENCIA = `${api}/rcdCreateLicencia`
export const RCDGETLICENCIABYPARAMS = `${api}/rcdGetLicenciaByParams`

// URLS VISITAS
export const RCDGETVISITASBYLICENCIAID = `${api}/rcdGetVistasByLicenciaId/`
export const RCDCREATEVISITA = `${api}/rcdCreateVisita`
export const RCDEDITVISITA = `${api}/rcdEditVisita`
export const RCDDELETEVISITA = `${api}/rcdDeleteVisita/`
export const RCDGETCANTVISITASESTADOFECHA = `${api}/rcdGetCantVisitasEstadoFecha`
export const RCDGETCANTVISESTFECHABYNUMACTA = `${api}/GetCantVisEstFechaByNumActa/`
export const RCDGETCANTVISESTFECHABYLICENCIA = `${api}/rcdGetCantVisEstFechaByLicencia/`
export const RCDGETVISITASBYDATE = `${api}/rcdGetVisitasByDate/`
export const RCDGENERARPDFRCD = `${api}/rcdGenerarPDFRCD/`
export const RCDGENERARPDFVYC = `${api}/rcdGenerarPDFVyC/`
// ---- Vigilancia y Control
export const RCDGETVISITASBYEMPRESAID = `${api}/rcdGetVistasByEmpresaId/`
export const RCDGETVISITASBYDATEEMPRESA = `${api}/rcdGetVisitasByDateEmpresa/`
export const RCDGETCANTVISESTFECHABYEMPRESA = `${api}/rcdGetCantVisEstFechaByEmpresa/`


// URL RESIDUOS SOLIDOS
export const RCDCREATERESSOLLICENCIA = `${api}/rcdCreateResSolLicencia`
export const RCDGETRESSOLLICENCIABYID = `${api}/rcdGetResSolLicenciaById/`
export const RCDEDITRESSOLLICENCIA = `${api}/rcdEditResSolLicencia`

// URL CIERRE VISITAS
export const RCDGETCIERREVISITABYID = `${api}/rcdGetCierreVisitaById/`
export const RCDCREATECIERREVISITA = `${api}/rcdCreateCierreVisita`
export const RCDEDITCIERREVISITA = `${api}/rcdEditCierreVisita`

// URL LOGIN
export const USRLOGINUSUARIO = `${api}/usrLoginUsuario`
export const FORGOT = `${api}/forgotpass`
export const RESET = `${api}/reset`

// VIGILANCIA CONTROL
// URL EMPRESAS
export const RCDGETACTIVIDADESECONOMICAS = `${api}/rcdGetActividadesEconomicas`
export const RCDGETCANTVISITASESTADOFECHAEMPRESA = `${api}/rcdGetCantVisitasEstadoFechaEmpresa`
export const RCDGETEMPRESAS = `${api}/rcdGetEmpresas`
export const RCDCREATEEMPRESAS = `${api}/rcdCreateEmpresa`
export const RCDBUSCAREMPRESAS = `${api}/rcdGetEmpresaByParams`
export const RCDDELETEMPRESAS = `${api}/rcdDeleteEmpresa/`
export const RCDEDITEMPRESAS = `${api}/rcdEditEmpresa`
// --PROVEEDORES
export const RCDGETPROVEEDORES = `${api}/rcdGetProveedores`


// TABS
// URL MATERIAS PRIMAS
export const RCDCREATEEMISIONESATMOSFERICAS = `${api}/rcdCreateEmisionAtmosferica`
export const RCDGETEMISIONESATMOSFERICASBYID = `${api}/rcdGetEmisionAtmosfericaById/`
export const RCDEDITEMISIONESATMOSFERICAS = `${api}/rcdEditEmisionAtmosferica`

// URL EMISIONES ATMOSFERICAS
export const RCDCREATEMATERIAPRIMA = `${api}/rcdCreateMatePrimaInsumo`
export const RCDGETMATERIAPRIMABYID = `${api}/rcdGetMatePrimaInsumoById/`
export const RCDEDITMATERIAPRIMA = `${api}/rcdEditMatePrimaInsumo`

// URL INFRAESTRUCTURA SERVICIOS
export const RCDCREATEINFRASERVICIO = `${api}/rcdCreateInfraServicio`
export const RCDGETINFRASERVICIOBYID = `${api}/rcdGetInfraServicioById/`
export const RCDEDITINFRASERVICIO = `${api}/rcdEditInfraServicio`

// URL ENERGIA ELECTRICA
export const RCDCREATEENERGIAELECTRICA = `${api}/rcdCreateEnergiaElectrica`
export const RCDGETENERGIAELECTRICABYID = `${api}/rcdGetEnergiaElectricaById/`
export const RCDEDITENERGIAELECTRICA = `${api}/rcdEditEnergiaElectrica`

// URL VERTIMIENTO
export const RCDCREATEVERTIMIENTO = `${api}/rcdCreateVertimiento`
export const RCDGETVERTIMIENTOBYID = `${api}/rcdGetVertimientoById/`
export const RCDEDITVERTIMIENTO = `${api}/rcdEditVertimiento`  

// URL RESIDUOS SOLIDOS
export const RCDCREATERESIDSOLIDOSVC = `${api}/rcdCreateResiduoSolido`
export const RCDGETRESIDSOLIDOSVCBYID = `${api}/rcdGetResiduoSolidoById/`
export const RCDEDITRESIDSOLIDOSVC = `${api}/rcdEditResiduoSolido`  

// URL RECURSOS NATURALES
export const RCDCREATERECURSOSNATURALES = `${api}/rcdCreateRecursoNatural`
export const RCDGETRECURSOSNATURALESBYID = `${api}/rcdGetRecursoNaturalById/`
export const RCDEDITRECURSOSNATURALES = `${api}/rcdEditRecursoNatural`  

// URL LISTA CHEQUEO
export const RCDCREATELISTACHEQUEO = `${api}/rcdCreateListaChequeo`
export const RCDGETLISTACHEQUEOBYID = `${api}/rcdGetListaChequeoById/`
export const RCDEDITLISTACHEQUEO = `${api}/rcdEditListaChequeo`  

// URL ANIMALES
export const TRMGETANIMALBYPARAMS = `${api}/trmGetAnimalByParams`
export const TRMCREATEANIMAL = `${api}/trmCreateAnimal`
export const TRMEDITANIMAL = `${api}/trmEditAnimal`
export const TRMGETANIMALBYID= `${api}/trmGetAnimalById/`
export const TRMGETRAZASBYTIPOANIMAL= `${api}/trmGetRazaByIdTipoAnimal/`
export const TRMGETTIPOANIMALES= `${api}/trmGetTipoAnimales`

// ATENCION VETERINARIA
export const TRMCREATEATENCIONVETERINARIA= `${api}/trmCreateAtencionVeterinaria`
export const TRMEDITATENCIONVETERINARIA= `${api}/trmEditAtencionVeterinaria`
export const TRMGETATENCIONVETERINARIABYIDANIMAL= `${api}/trmGetAtencionVeterinariaByIdAnimal/`

// PROCEDIMIETOS
export const TRMCREATEATENCIONPROCEDIMIENTO = `${api}/trmCreateAtencionProcedimiento`
export const TRMEDITATENCIONPROCEDIMIENTO = `${api}/trmEditAtencionProcedimiento`
export const TRMGETATENCIONPROCEDIMIENTOBYID = `${api}/trmGetAtencionProcedimientoById/`
export const TRMGETPROCEDIMIENTOSBYIDATENCIONVETERINARIA = `${api}/trmGetProcedimientosByIdAtenVeterinaria/`

// AUTORIZACION PROCEDIMIENTOS
export const TRMCREATEAUTORIZACIONEUTANASIA = `${api}/trmCreateAutorizacionEutanasia`
export const TRMEDITAUTORIZACIONEUTANASIA = `${api}/trmEditAutorizacionEutanasia`
export const TRMGETAUTORIZACIONEUTANASIABYID = `${api}/trmGetAutorizacionEutanasiaById/`

// PROCEDIMIENTOS
export const TRMGETPROCEDIMIENTOS = `${api}/trmGetProcedimientos/`

// ADOPCION
export const TRMGETSOLICITUDADOPCIONBYPARAMS = `${api}/trmGetSolicitudAdopcionByParams`
export const TRMGETSOLICITUDADOPCIONBYID = `${api}/trmGetSolicitudAdopcionById/`
export const TRMEDITSOLICITUDADOPCION = `${api}/trmEditSolicitudAdopcion`
export const TRMEDITCREATESOLICITUDADOPCION = `${api}/trmCreateSolicitudAdopcion`

// ANIMAL PERSONA
export const TRMCREATEANIMALPERSONA = `${api}/trmCreateAnimalPersona`
export const TRMGETANIMALPERSONABYIDANIMAL = `${api}/trmGetAnimalPersonaByIdAnimal/`

// RECICLADORES
export const RECGETMUNICIPIOS = `${api}/recGetMunicipios`
export const RECGETMUNICIPIOSBYIDDEPARTAMENTO = `${api}/recGetMunicipiosByIdDepartamento/`
export const RCDGETASOCIACION = `${api}/recGetAsociacion/`
export const TRMGETREGISTROBYPARAMS = `${api}/trmGetRegistroByParams`
export const TRMCREATEREGISTRO = `${api}/trmCreateRegistro`
export const TRMEDITREGISTRO = `${api}/trmEditRegistro`
export const TRMGETREGISTROBYID = `${api}/trmGetRegistroById/`
export const TRMGETREGISTROBYIDENTIFICACION = `${api}/trmGetRegistroByIdentificacion/`


// EVENTOS
export const RCDGETEVENTO = `${api}/rcdGetEvento`
export const RCDCREATEEVENTO = `${api}/rcdCreateEvento`
export const RCDEDITEVENTO = `${api}/rcdEditEvento`
export const RECGETEVENTOBYID = `${api}/recGetEventoById/`
export const RECGETEVENTOBYPARAMS = `${api}/recGeteventoByParams`

// ASISTENTE EVENTOS
export const RCDCREATEASISTENTEEVENTO = `${api}/rcdCreateAsistenteEvento`
export const RECGETASSITENTEEVENTOBYIDEVENTO = `${api}/recGetAsistenteEventoByIdEvento/`
export const RECGETEVENTOBYIDASISTENTE = `${api}/recGetEventoByIdAsistente/`

// PERSONAS
export const TRMLOGINPERSONA = `${api}/trmLoginPersona`
export const TRMFORGOTPASS = `${api}/trmforgotpass`
export const TRMRESET = `${api}/trmreset`
export const TRMGETPERSONABYID = `${api}/trmGetPersonaById/`
export const TRMCREATEPERSONA = `${api}/trmCreatePersona`
export const TRMEDITPERSONA = `${api}/trmEditPersona`