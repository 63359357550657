<template>
  <div id="app">
    <b-overlay :show="loading" rounded="sm" z-index=999>
      <BarraMenu />
      <FranjaImagenTitulo />

      <b-container class="viewRouter">
        <router-view />
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import BarraMenu from './components/BarraMenu.vue'
import FranjaImagenTitulo from './components/FranjaImagenTitulo.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    BarraMenu,
    FranjaImagenTitulo
  },
  computed: {
    ...mapState(['loading'])
  }
}
</script>

<style scoped>
  .viewRouter{
    margin-top: -140px;
    z-index: 99;
    position: relative;
  }

</style>