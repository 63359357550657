import axios from 'axios';
import {
    USRLOGINUSUARIO,
    FORGOT,
    RESET
} from '../constantes'

export default {
    namespaced: true,
    state: {
        respuestaLogin: {},
        respuestaForgot: {},
        tokenRecuperacion: null,
        resTokenRecuperacion: {}
    },
    mutations:{
        setResLogin(state, payload){
            state.respuestaLogin = payload
        },
        setResForgotPass(state, payload){
            state.respuestaForgot = payload
        },
        setTokenRecuperacion(state, payload){
            state.tokenRecuperacion = payload
        },
        setResTokenRecuperacion(state, payload){
            state.resTokenRecuperacion = payload
        }
    },
    actions: {
        async login({commit}, login){
            return axios.post(USRLOGINUSUARIO, login)
                .then(res=>{
                    console.log(res)
                    commit('setResLogin', res.data)
                })
                .catch(error=>{return Promise.reject(error.response)})
        },
        async forgotPass({commit}, mail){
            return axios.post(FORGOT, mail)
                .then(res=>{
                    console.log(res)
                    commit('setResForgotPass', res.data)
                })
                .catch(error=>{return Promise.reject(error.response)})
        },

        setTokenRecueracion({commit},token){
            commit('setTokenRecuperacion', token)
        },

        async resetPass({commit}, datos){
            return axios.post(RESET, datos)
                .then(res=>{
                    console.log(res)
                    commit('setResTokenRecuperacion', res.data)
                })
                .catch(error=>{return Promise.reject(error.response)})
        },
    },
}