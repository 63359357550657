import axios from 'axios';
import {
  RECGETMUNICIPIOSBYIDDEPARTAMENTO,
  RCDGETASOCIACION,
  TRMGETREGISTROBYPARAMS,
  TRMCREATEREGISTRO,
  TRMEDITREGISTRO,
  TRMGETREGISTROBYID,
  RECGETEVENTOBYPARAMS,
  RECGETEVENTOBYID,
  RCDCREATEEVENTO,
  RCDEDITEVENTO,
  TRMGETREGISTROBYIDENTIFICACION,
  RCDCREATEASISTENTEEVENTO,
  RECGETASSITENTEEVENTOBYIDEVENTO,
  RECGETMUNICIPIOS,
  RECGETEVENTOBYIDASISTENTE,
} from '../../constantes'

export default {
  namespaced: true,
  state: {
    municipiosServicios: [],

    recicladoresServicios: [],
    recicladorSeleccionado: {},
    recicladorCargado: {},
    recicladorCargadoByIdentificacion: {},
    resultCreateReciclador: {},

    asociaciones: [],

    eventosServicios: [],
    eventoSeleccionado: {},
    eventoCargado: {},
    resultCreateEvento: {},


    asistentesServicios: [],
    resultCreateAsistentesEventos:{},
    eventosByAsistenteServicio: []
  },
  mutations: {
    setMunicipiosServicios(state, payload) {
      state.municipiosServicios = payload
    },
    setRecicladoresServicios(state, payload) {
      state.recicladoresServicios = payload
    },
    setRecicladorCargado(state, payload) {
      state.recicladorCargado = payload
    },
    setRecicladorCargadoByIdentificacion(state, payload) {
      state.recicladorCargadoByIdentificacion = payload
    },
    setRecicladorSeleccionado(state, payload) {
      state.recicladorSeleccionado = payload
    },
    setResultCreateReciclador(state, payload){
      state.resultCreateReciclador = payload
    },
    setAsociaciones(state, payload) {
      state.asociaciones = payload
    },
    setEventosServicios(state, payload) {
      state.eventosServicios = payload
    },
    setEventoSeleccionado(state, payload) {
      state.eventoSeleccionado = payload
    },
    setEventoCargado(state, payload) {
      state.eventoCargado = payload
    },
    setResultCreateEvento(state, payload){
      state.resultCreateEvento = payload
    },
    setAsistentesServicios(state, payload) {
      state.asistentesServicios = payload
    },
    setResulstCreateAsistentesServicios(state, payload) {
      state.resultCreateAsistentesEventos = payload
    },
    setEventosByAsistenteServicio(state, payload) {
      state.eventosByAsistenteServicio = payload
    },
  },
  actions: {

    async cargarMunicipiosCompletos({ commit }) {
      return axios.get(RECGETMUNICIPIOS)
        .then(results => {
          commit('setMunicipiosServicios', results.data.data)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },
    
    async cargarMunicipiosByIdDepartamento({ commit }, idDepartamento) {
      return axios.put(RECGETMUNICIPIOSBYIDDEPARTAMENTO + idDepartamento)
        .then(results => {
          commit('setMunicipiosServicios', results.data.data)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },

    async buscarRecicladores({ commit }, filtros) {
      return axios.post(TRMGETREGISTROBYPARAMS, filtros)
        .then(results => {
          console.log(results)
          commit('setRecicladoresServicios', results.data.data)
        })
        .catch(error => { return Promise.reject(error.response) })
    },

    asignarRecicladorSeleccionado({ commit }, reciclador) {
      commit('setRecicladorSeleccionado', reciclador)
    },

    async createReciclador({commit}, reciclador){
      return axios.post(TRMCREATEREGISTRO, reciclador)
        .then(results=>{
          commit('setResultCreateReciclador', results)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },

    async editReciclador({commit}, reciclador){
      return axios.post(TRMEDITREGISTRO, reciclador)
        .then(results=>{
          commit('setResultCreateReciclador', results)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },

    async cargarRecicladorById({ commit }, id) {
      return axios.put(TRMGETREGISTROBYID + id)
          .then(results => {
              commit('setRecicladorCargado', results.data.data[0])
          })
          .catch(error => {
              if (error.response) {
                  return Promise.reject(error.response)
              }
          })
    },
    
    async cargarRecicladorByIdentificacion({ commit }, identificacion) {
      return axios.put(TRMGETREGISTROBYIDENTIFICACION + identificacion)
          .then(results => {
              commit('setRecicladorCargadoByIdentificacion', results.data.data[0])
          })
          .catch(error => {
              if (error.response) {
                  return Promise.reject(error.response)
              }
          })
    },

    async cargarAsociaciones({ commit }) {
      return axios.get(RCDGETASOCIACION)
        .then(results => {
          commit('setAsociaciones', results.data.data)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },

    async buscarEventos({ commit }, filtros) {
      
      return axios.post(RECGETEVENTOBYPARAMS, filtros)
        .then(results => {
          commit('setEventosServicios', results.data.data)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },

    asignarEventoSeleccionado({ commit }, evento) {
      commit('setEventoSeleccionado', evento)
    },

    async cargarEventoById({ commit }, id) {
      return axios.put(RECGETEVENTOBYID + id)
        .then(results => {
            commit('setEventoCargado', results.data.data[0])
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response)
            }
        })
    },

    async createEvento({commit}, evento){
      return axios.post(RCDCREATEEVENTO, evento)
        .then(results=>{
          commit('setResultCreateEvento', results)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },

    async editEvento({commit}, evento){
      return axios.post(RCDEDITEVENTO, evento)
        .then(results=>{
          commit('setResultCreateEvento', results)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },

    async createAsistenteEvento({commit}, evento){
      return axios.post(RCDCREATEASISTENTEEVENTO, evento)
        .then(results=>{
          commit('setResulstCreateAsistentesServicios', results)
        })
        .catch(error => {
          if (error.response) {
            return Promise.reject(error.response)
          }
        })
    },

    async TraerAsistentesEvento({ commit }, idEvento) {
      return axios.put(RECGETASSITENTEEVENTOBYIDEVENTO + idEvento)
        .then(results => {
          console.log(results)
          commit('setAsistentesServicios', results.data.data)
        })
        .catch(error => { return Promise.reject(error.response) })
    },

    async TraerEventosByAsistente({ commit }, idReciclador) {
      return axios.put(RECGETEVENTOBYIDASISTENTE + idReciclador)
        .then(results => {
          console.log(results)
          commit('setEventosByAsistenteServicio', results.data.data)
        })
        .catch(error => { return Promise.reject(error.response) })
    },
  }
}