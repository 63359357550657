<template>
  <div>
    <b-navbar type="dark" variant="primary">
      <b-container>
        <b-dropdown
          size="sm"
          variant="primary"
          toggle-class="text-decoration-none"
          no-caret
          id="menuPrincipal"
        >
          <template #button-content v-if="validarMenu">
            <span>MENÚ</span>
            <span class="navbar-toggler-icon"></span>
          </template>
          <ul class="menus">
            <!-- RCD -->
            <b-dropdown-item v-if="verMenuRCD">
              Residuos de Construcción y Demolición
              <ul class="submenus" v-bind:class="{ activo: isSubRcd }">
                <b-dropdown-item :to="{ name: 'subirArchivos' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Subir Archivos
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'consultaLicencias' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Consulta Licencias
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'gestionVisita' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Gestión Visita
                </b-dropdown-item>
              </ul>
              <div class="icono" @click="subRcd">
                <b-icon
                  icon="chevron-down"
                  v-bind:class="{ flip: isSubRcd }"
                ></b-icon>
              </div>
            </b-dropdown-item>

            <!-- VIGILANCIA Y CONTROL -->
            <b-dropdown-item v-if="verMenuVYC">
              Vigilancia y Control
              <ul
                class="submenus"
                v-bind:class="{ activo: isSubVigilanciaControl }"
              >
                <b-dropdown-item :to="{ name: 'consultaEmpresas' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Gestión Empresas
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'agendarVisitaVigilanciaControl' }" >
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Agendar Visitas
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'gestionarVisitaVigilanciaControl' }" >
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Gestión Visita
                </b-dropdown-item>
              </ul>
              <div class="icono" @click="subVigilanciaControl">
                <b-icon
                  icon="chevron-down"
                  v-bind:class="{ flip: isSubVigilanciaControl }"
                ></b-icon>
              </div>
            </b-dropdown-item>

            <!-- ANIMALES| -->
            <b-dropdown-item v-if="verMenuAnimales">
              Animales
              <ul class="submenus" v-bind:class="{ activo: isSubAnimales }">
                <b-dropdown-item :to="{ name: 'consultaAnimales' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Consulta Animales
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'consultaAdopcion' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Consulta Adopciones
                </b-dropdown-item>
              </ul>
              <div class="icono" @click="subAnimales">
                <b-icon
                  icon="chevron-down"
                  v-bind:class="{ flip: isSubAnimales }"
                ></b-icon>
              </div>
            </b-dropdown-item>

            <!-- RECICLADORES -->
            <b-dropdown-item v-if="verMenuRecicladores">
              Recicladores
              <ul class="submenus" v-bind:class="{ activo: isSubRecicladores }">
                <b-dropdown-item :to="{ name: 'cargaArchivosRecicladores' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Cargar archivos
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'consultaRecicladores' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Consultar censo recicladores
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'consultaJornadas' }">
                  <b-icon icon="chevron-double-right" font-scale="1"></b-icon>
                  Jornada de intervención
                </b-dropdown-item>
              </ul>
              <div class="icono" @click="subRecicladores">
                <b-icon
                  icon="chevron-down"
                  v-bind:class="{ flip: isSubRecicladores }"
                ></b-icon>
              </div>
            </b-dropdown-item>
          </ul>
        </b-dropdown>
        <img alt="Logo Gov Co" src="../assets/logo_gov_co.jpg" right />
      </b-container>
    </b-navbar>

    <b-container>
      <b-row>
        <b-col>
          <img
            alt="Alcaldía Mosquera"
            src="../assets/alcaldia_mosquera.jpg"
            class="imagenesBarra img-responsive"
            size="md"
          />
        </b-col>
        <b-col>
          <img
            alt="Gian Gerometta"
            src="../assets/gian_gerometta.jpg"
            class="float-right imagenesBarra img-responsive"
            size="md"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BarraMenu",
  data() {
    return {
      usuario: {},
      isDropdownVisible: false,
      isSubmenu: false,
      isSubVigilanciaControl: false,
      isSubRcd: false,
      isSubAnimales: false,
      isSubRecicladores: false,
      verMenuRCD: false,
      verMenuVYC: false,
      verMenuAnimales: false,
      verMenuRecicladores: false,
    };
  },
  watch: {
    respuestaLogin: function (newVal, oldVal) {
        console.log('aqui')
      if (newVal != oldVal && newVal.data[0].email) {
          setTimeout(() => {
              if (localStorage.getItem("usuario")) {
                try {
                  this.usuario = JSON.parse(localStorage.getItem("usuario"));
                  this.autorizarAcceso();
                } catch (e) {
                  localStorage.removeItem("usuario");
                }
              }
          }, 300);
      }
    },
  },
  mounted() {

    setTimeout(() => {
      if (localStorage.getItem("usuario")) {
        try {
          this.usuario = JSON.parse(localStorage.getItem("usuario"));
          this.autorizarAcceso();
        } catch (e) {
          localStorage.removeItem("usuario");
        }
      }
    }, 300);
          
    this.$root.$on("bv::dropdown::show", () => {
      this.isDropdownVisible = true;
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (this.isDropdownVisible && this.isSubmenu) {
        bvEvent.preventDefault();
        this.isSubmenu = false;
      }
    });
  },
  computed: {
    ...mapState("usuarios", ["respuestaLogin"]),
    validarMenu(){
      if(
        this.$route.name != 'login' && 
        this.$route.name != 'loginPersonas' && 
        this.$route.name != 'personas' &&
        this.$route.name != 'misDatos' &&
        this.$route.name != 'misMascotas' &&
        this.$route.name != 'misSolicitudes'
        ){
        return true
      }
      return false
      
    }
  },
  methods: {
    subVigilanciaControl() {
      this.isSubmenu = true;
      this.isSubVigilanciaControl = !this.isSubVigilanciaControl;
    },
    subRcd() {
      this.isSubmenu = true;
      this.isSubRcd = !this.isSubRcd;
    },
    subAnimales() {
      this.isSubmenu = true;
      this.isSubAnimales = !this.isSubAnimales;
    },
    subRecicladores() {
      this.isSubmenu = true;
      this.isSubRecicladores = !this.isSubRecicladores;
    },
    autorizarAcceso() {
      this.usuario.perfil.forEach((item) => {
        if (item.id_perfil == 101 || item.id_perfil == 102) {
          this.verMenuRCD = true;
        }
        if (item.id_perfil == 101 || item.id_perfil == 103) {
          this.verMenuVYC = true;
        }
        if (item.id_perfil == 101 || item.id_perfil == 104) {
          this.verMenuAnimales = true;
        }
        if (item.id_perfil == 101 || item.id_perfil == 105) {
          this.verMenuRecicladores = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.imagenesBarra {
  max-width: 80%;
}
</style>