import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt from 'jsonwebtoken'
import {TOKEN_SECRET} from '../constantes'
// import storeUsuarios from '../store/Usuarios'
var usuario = {}
var persona = {}



Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "login" */ '../views/Home.vue'),
  },

  {
    path: '*',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },

  {
    path: '/loginpersonas',
    name: 'loginPersonas',
    component: () => import(/* webpackChunkName: "loginPersonas" */ '../views/LoginPersonas.vue'),
  },
  
  {
    path: '/reset/:token',
    name: 'reset',
    component: () => import(/* webpackChunkName: "reset" */ '../views/Login.vue'),
  },
  {
    path: '/resetpersonas/:token',
    name: 'resetPersonas',
    component: () => import(/* webpackChunkName: "resetPersona" */ '../views/LoginPersonas.vue'),
  },

  {
    path: '/gestionvisita',
    name: 'gestionVisita',
    meta: {
      requiresAuth: {
        perfiles: [101, 102]
      }
    },
    component: () => import(/* webpackChunkName: "gestionvisita" */ '../views/GestionVisita.vue'),
  },
  {
    path: '/subirarchivos',
    name: 'subirArchivos',
    meta: {
      requiresAuth: {
        perfiles: [101, 102]
      }
    },
    component: () => import(/* webpackChunkName: "subirArchivos" */ '../views/CargaArchivos.vue')
  },
  {
    path: '/consultalicencias',
    name: 'consultaLicencias',
    meta: {
      requiresAuth: {
        perfiles: [101, 102]
      }
    },
    component: () => import(/* webpackChunkName: "consultaLicencias" */ '../views/ConsultaLicencias.vue')
  },
  {
    path: '/agendarvisita',
    name: 'agendarVisita',
    meta: {
      requiresAuth: {
        perfiles: [101, 102]
      }
    },
    component: () => import(/* webpackChunkName: "agendarVisita" */ '../views/AgendarVisita.vue')
  },
  {
    path: '/formulariogestionvisita',
    name: 'formularioGestionVisita',
    meta: {
      requiresAuth: {
        perfiles: [101, 102]
      }
    },
    component: () => import(/* webpackChunkName: "formularioGestionVisita" */ '../views/FormularioGestionVisita.vue'),
    children:[
      {
        path: '/',
        name: 'datosBasicos',
        component: () => import(/* webpackChunkName: "datosBasicos" */ '../views/formularioGestionRCD/DatosBasicos.vue'),
      },
      {
        path: '/residuossolidos',
        name: 'residuosSolidos',
        component: () => import(/* webpackChunkName: "residuosSolidos" */ '../views/formularioGestionRCD/ResiduosSolidos.vue'),
      },
      {
        path: '/cierrevisita',
        name: 'cierreVisita',
        component: () => import(/* webpackChunkName: "cierreVisita" */ '../views/formularioGestionRCD/CierreVisita.vue'),
      },
    ]
  },

  // VIGILANCIA Y CONTROL
  {
    path: '/agendarvisitavigilanciacontrol',
    name: 'agendarVisitaVigilanciaControl',
    meta: {
      requiresAuth: {
        perfiles: [101, 103]
      }
    },
    component: () => import(/* webpackChunkName: "agendarVisitaVigilanciaControl" */ '../views/vigilanciaControl/AgendarVisita.vue')
  },
  {
    path: '/consultaempresas',
    name: 'consultaEmpresas',
    meta: {
      requiresAuth: {
        perfiles: [101, 103]
      }
    },
    component: () => import(/* webpackChunkName: "consultaEmpresas" */ '../views/vigilanciaControl/ConsultaEmpresas.vue')
  },
  {
    path: '/gestionarvisitavigilanciacontrol',
    name: 'gestionarVisitaVigilanciaControl',
    meta: {
      requiresAuth: {
        perfiles: [101, 103]
      }
    },
    component: () => import(/* webpackChunkName: "gestionarVisitaVigilanciaControl" */ '../views/vigilanciaControl/GestionVisita.vue')
  },
  {
    path: '/formulariogestionvisitavigilanciacontrol',
    name: 'formularioGestionVisitaVigilanciaControl',
    meta: {
      requiresAuth: {
        perfiles: [101, 103]
      }
    },
    component: () => import(/* webpackChunkName: "formularioGestionVisitaVigilanciaControl" */ '../views/vigilanciaControl/FormularioGestionVisitaVigilanciaControl.vue'),
    children:[
      {
        path: '/',
        name: 'datosBasicosVigilanciaControl',
        component: () => import(/* webpackChunkName: "datosBasicosVigilanciaControl" */ '../views/formularioGestionRCD/DatosBasicos.vue'),
      },
      {
        path: '/materiaprimavigilanciacontrol',
        name: 'materiaPrimaVigilanciaControl',
        component: () => import(/* webpackChunkName: "materiaPrimaVigilanciaControl" */ '../views/vigilanciaControl/formularioGestion/MateriaPrima.vue'),
      },
      {
        path: '/infraestructuraserviciosvigilanciacontrol',
        name: 'infraestructuraServiciosVigilanciaControl',
        component: () => import(/* webpackChunkName: "infraestructuraServiciosVigilanciaControl" */ '../views/vigilanciaControl/formularioGestion/InfraestructuraServicios.vue'),
      },
      {
        path: '/energiaelectricavigilanciacontrol',
        name: 'energiaElectricaVigilanciaControl',
        component: () => import(/* webpackChunkName: "energiaElectricaVigilanciaControl" */ '../views/vigilanciaControl/formularioGestion/EnergiaElectrica.vue'),
      },
      {
        path: '/vertimientovigilanciacontrol',
        name: 'vertimientoVigilanciaControl',
        component: () => import(/* webpackChunkName: "vertimientoVigilanciaControl" */ '../views/vigilanciaControl/formularioGestion/Vertimiento.vue'),
      },
      {
        path: '/emisionesatmosfericasvigilanciacontrol',
        name: 'emisionesAtmosfericasVigilanciaControl',
        component: () => import(/* webpackChunkName: "emisionesAtmosfericasVigilanciaControl" */ '../views/vigilanciaControl/formularioGestion/EmisionesAtmosfericas.vue'),
      },
      {
        path: '/residuossolidosvigilanciacontrol',
        name: 'residuosSolidosVigilanciaControl',
        component: () => import(/* webpackChunkName: "residuosSolidosVigilanciaControl" */ '../views/vigilanciaControl/formularioGestion/ResiduosSolidos.vue'),
      },
      {
        path: '/recursosnaturalesvigilanciacontrol',
        name: 'recursosnaturalesVigilanciaControl',
        component: () => import(/* webpackChunkName: "recursosnaturalesVigilanciaControl" */ '../views/vigilanciaControl/formularioGestion/RecursosNaturales.vue'),
      },
      {
        path: '/listachequeovigilanciacontrol',
        name: 'listaChequeoVigilanciaControl',
        component: () => import(/* webpackChunkName: "listaChequeoVigilanciaControl" */ '../views/vigilanciaControl/formularioGestion/ListaChequeo.vue'),
      },
      {
        path: '/cierrevisitavigilanciacontrol',
        name: 'cierreVisitaVigilanciaControl',
        component: () => import(/* webpackChunkName: "cierreVisitaVigilanciaControl" */ '../views/formularioGestionRCD/CierreVisita.vue'),
      },
    ]
  },

  // ANIMALES 
  {
    path: '/consultaanimales',
    name: 'consultaAnimales',
    meta: {
      requiresAuth: {
        perfiles: [101, 104]
      }
    },
    component: () => import(/* webpackChunkName: "consultaAnimales" */ '../views/animales/consultaAnimales.vue')
  },
  {
    path: '/consultaadopcion',
    name: 'consultaAdopcion',
    meta: {
      requiresAuth: {
        perfiles: [101, 104]
      }
    },
    component: () => import(/* webpackChunkName: "consultaAdopcion" */ '../views/animales/adopcion/ConsultaAdopcion.vue')
  },

   // RECICLADORES
   {
    path: '/cargaarchivosrecicladores',
    name: 'cargaArchivosRecicladores',
    meta: {
      requiresAuth: {
        perfiles: [101, 105]
      }
    },
    component: () => import(/* webpackChunkName: "cargaArchivosRecicladores" */ '../views/recicladores/CargaArchivosRecicladores.vue')
  },
  {
    path: '/consultacensorecicladores',
    name: 'consultaRecicladores',
    meta: {
      requiresAuth: {
        perfiles: [101, 105]
      }
    },
    component: () => import(/* webpackChunkName: "consultaRecicladores" */ '../views/recicladores/ConsultarCensoRecicladores.vue')
  },
  {
    path: '/consultajornadas',
    name: 'consultaJornadas',
    meta: {
      requiresAuth: {
        perfiles: [101, 105]
      }
    },
    component: () => import(/* webpackChunkName: "consultaJornadas" */ '../views/recicladores/ConsultarJornadas.vue')
  },
   
  // PERSONAS
   {
    path: '/personas',
    name: 'personas',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "personas" */ '../views/personas/vistaPersonas.vue'),
    children: [
      {
        path: '/',
        name: 'misDatos',
        component: () => import(/* webpackChunkName: "misDatos" */ '../views/personas/subVistas/datosPersonales.vue'),
      },
      {
        path: '/mismascotas',
        name: 'misMascotas',
        component: () => import(/* webpackChunkName: "misMascotas" */ '../views/personas/subVistas/Mascotas.vue'),
      },
      {
        path: '/missolicitudes',
        name: 'misSolicitudes',
        component: () => import(/* webpackChunkName: "misSolicitudes" */ '../views/personas/subVistas/solicitudesAdopcion.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name=='login'){
    localStorage.removeItem("usuario");
    localStorage.removeItem("persona");
  }
  
  if(to.name=='loginPersonas'){
    localStorage.removeItem("usuario");
    localStorage.removeItem("persona");
  }

  if (localStorage.getItem("usuario")) {
    try {
      usuario = JSON.parse(localStorage.getItem("usuario"));
      console.log(usuario)
    } catch (e) {
      localStorage.removeItem("usuario");
    }
  }
  
  if (localStorage.getItem("persona")) {
    try {
      persona = JSON.parse(localStorage.getItem("persona"));
      console.log(persona)
    } catch (e) {
      localStorage.removeItem("persona");
    }
  }
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(usuario.contrasena){
      jwt.verify(usuario.token, TOKEN_SECRET, function(err, tokenValido) {
        if(err){
          next({ name: "login" });
        }
        if (usuario && tokenValido) {
          if(to.meta.requiresAuth){
            for(let i=0; i < usuario.perfil.length; i++){
              if(to.meta.requiresAuth.perfiles.includes(usuario.perfil[i].id_perfil)){
                next();
                break;
              }
              if(i==usuario.perfil.length-1){
                next({ name: "login" });
              }
            }
          }else{
            next();
          }
        } else {
          next({ name: "login" });
        }
      });
    }else{
      console.log('entro a persona')
      jwt.verify(persona.token, TOKEN_SECRET, function(err, tokenValidoPersonas) {
        if(err){
          next({ name: "loginPersonas" });
        }
        if (persona && tokenValidoPersonas) {
          if(to.meta.requiresAuth && to.meta.requiresAuth.perfiles.length>0){
            next({ name: "loginPersonas" });
          }else{
            next();
          }
        } else {
          next({ name: "loginPersonas" });
        }
      });
    }
  } else {
    next();
  }
});

export default router
