import Vue from 'vue'
import Vuex from 'vuex'

// IMAGENES ENCABEZADOS
import imagenGestion from '../assets/imgCabeceras/gestionarVisita.jpg'
import imagenSubirArchivos from '../assets/imgCabeceras/subirArchivos.jpg'
import imagenConsultaLicencias from '../assets/imgCabeceras/consultaLicencias.jpg'
import imagenAgendarVisita from '../assets/imgCabeceras/agregarVisita.jpg'
import imagenFormularioGestionVisita from '../assets/imgCabeceras/formularioGestionVisita.jpg'
import imagenLogin from '../assets/imgCabeceras/login.jpg'
import imagenLoginPersonas from '../assets/imgCabeceras/loginPersonas.jpg'

// IMAGENES ANIMALES 
import imagenConsultaAnimales from '../assets/imgCabeceras/consultaAnimales.jpg'
import imagenConsultaAdopcion from '../assets/imgCabeceras/consultaAdopcion.jpg'

// IMAGENES RECICLADORES 
import imagenCargaArchivosRecicladores from '../assets/imgCabeceras/cargarArchivosRecicladores.jpg'
import imagenConsultaRecicladores from '../assets/imgCabeceras/consultaRecicladores.jpg'

// IMAGENES PERSONAS 
import imagenVistaPersonas from '../assets/imgCabeceras/vistaPersonas.jpg'

// IMPORTAR STORES
import consultaLicencias from './ConsultaLicencias'
import agendarVisita from './AgendarVisita'
import gestionarVisitas from './GestionVisitas'
import usuarios from './Usuarios'

// VIGILANCIA Y CONTROL
import consultaEmpresas from './vigilanciaControl/ConsultaEmpresas'
import gestionarVisitasVigilanciaControl from './vigilanciaControl/gestionVisitaVigilanciaControl'

// ANIMALES 
import animales from './animales/Animales'
import animalesPersona from './animales/AnimalPersona'

// RECICLADORES 
import recicladores from './recicladores/Recicladores'

// PERSONAS 
import personas from './personas/persona'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imagenesCabeceras: [
      {
        ruta: imagenLogin,
        name: ['login', 'reset'],
        titulo: `Ingresar al Sistema de información de la secretaría de ambiente y desarrollo agropecuario`
      },
      {
        ruta: imagenLogin,
        name: ['home'],
        titulo: `Sistema de información de la secretaría de ambiente y desarrollo agropecuario`
      },
      {
        ruta: imagenLoginPersonas,
        name: ['loginPersonas', 'resetPersonas'],
        titulo: `Ingresar al Sistema de información de la secretaría de ambiente y desarrollo agropecuario`
      },
      {
        ruta: imagenGestion,
        name: ['gestionVisita'],
        titulo: 'Gestionar Visita'
      },
      {
        ruta: imagenSubirArchivos,
        name: ['subirArchivos'],
        titulo: 'Subir Archivos'
      },
      {
        ruta: imagenConsultaLicencias,
        name: ['consultaLicencias'],
        titulo: 'Consulta de Licencias de Construcción'
      },
      {
        ruta: imagenAgendarVisita,
        name: ['agendarVisita'],
        titulo: 'Agendar Visita'
      },
      {
        ruta: imagenFormularioGestionVisita,
        name: ['formularioGestionVisita', 'datosBasicos', 'residuosSolidos', 'cierreVisita'],
        titulo: 'Gestionar Visita'
      },

      // VIGILANCIA Y CONTROL
      {
        ruta: imagenAgendarVisita,
        name: ['agendarVisitaVigilanciaControl'],
        titulo: 'Agendar Visita Vigilancia y Control'
      },
      {
        ruta: imagenConsultaLicencias,
        name: ['consultaEmpresas'],
        titulo: 'Gestionar Empresas'
      },
      {
        ruta: imagenGestion,
        name: ['gestionarVisitaVigilanciaControl',],
        titulo: 'Gestionar Visita Vigilancia y Control'
      },
      {
        ruta: imagenFormularioGestionVisita,
        name: [
          'formularioGestionVisitaVigilanciaControl',
          'datosBasicosVigilanciaControl',
          'materiaPrimaVigilanciaControl',
          'residuosSolidosVigilanciaControl',
          'cierreVisitaVigilanciaControl',
          'infraestructuraServiciosVigilanciaControl',
          'energiaElectricaVigilanciaControl',
          'vertimientoVigilanciaControl',
          'emisionesAtmosfericasVigilanciaControl',
          'recursosnaturalesVigilanciaControl',
          'listaChequeoVigilanciaControl',
        ],
        titulo: 'Gestionar Visita Vigilancia y Control'
      },

      // ANIMALES 
      {
        ruta: imagenConsultaAnimales,
        name: ['consultaAnimales',],
        titulo: 'Gestión de Animales'
      },
      {
        ruta: imagenConsultaAdopcion,
        name: ['consultaAdopcion',],
        titulo: 'Consulta - Gestión de Solicitudes de Adopción'
      },

      // RECICLADORES 
      {
        ruta: imagenCargaArchivosRecicladores,
        name: ['cargaArchivosRecicladores',],
        titulo: 'Cargar Información Censo Recicladores'
      },
      {
        ruta: imagenConsultaRecicladores,
        name: ['consultaRecicladores',],
        titulo: 'Consulta Información Censo Recicladores'
      },
      {
        ruta: imagenConsultaRecicladores,
        name: ['consultaJornadas',],
        titulo: 'Jornadas de Intervención a la Población Recicladora'
      },

      // PERSONAS 
      {
        ruta: imagenVistaPersonas,
        name: ['personas', 'misMascotas', 'misDatos', 'misSolicitudes'],
        titulo: 'Tenencia Responsable de Mascotas'
      },
    ],
    loading: false
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    }
  },
  actions: {
    activarLoading({ commit }, val) {
      commit('setLoading', val)
    }
  },
  modules: {
    consultaLicencias,
    agendarVisita,
    gestionarVisitas,
    usuarios,
    consultaEmpresas,
    gestionarVisitasVigilanciaControl,
    animales,
    animalesPersona,
    recicladores,
    personas
  }
})
