import axios from 'axios';
import {RCDGETLICENCIABYPARAMS} from '../constantes';

export default {
    namespaced:true,
    state: {
        licenciasServicio: [],
        licenciaSeleccionada: {}
    },
    mutations:{
        setLicencias(state, payload){
            state.licenciasServicio = payload
        },
        setLicenciaSeleccionada(state, payload){
            console.log(payload)
            state.licenciaSeleccionada = payload
        }
    },
    actions:{
        async buscarLicencias({commit}, params){
            let filters = {licenciaparams: {...params}}
            return axios.post(RCDGETLICENCIABYPARAMS, filters)
                .then(results=>{
                    console.log(results)
                    commit('setLicencias', results.data.data)
                })
                .catch(error=>{return Promise.reject(error.response)})
        },
        asignarLicenciaSeleccionada({commit}, licencia){
            commit('setLicenciaSeleccionada', licencia)
        }
    },
}