<template>
        <div :style="{ backgroundImage: `url(${imagenCabecera.ruta})`}" class="imagenCabecera"  v-bind:class="{ homeContainer: $route.name == 'home' }">
            <b-container>
                <h3 v-bind:class="{ home: $route.name == 'home' }" >{{imagenCabecera.titulo}}</h3>
                <p v-if="imagenCabecera.ruta!=null">Alcaldía municipal de Mosquera</p>
            </b-container>
        </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'franjaImagenTitulo',
        data(){
            return{
                imagen: null,
                titulo:null
            }
        },
        computed: {
            ...mapState(['imagenesCabeceras']),
            imagenCabecera(){
                let imagen={
                    ruta: null,
                    titulo: 'Alcaldía de Mosquera'
                }
                this.imagenesCabeceras.find(item=>{
                    if(item.name.includes(this.$route.name)){
                        imagen.ruta = item.ruta;
                        imagen.titulo = item.titulo;
                    }
                })
                return imagen;
            }
        },

    }
</script>

<style scoped>
    .imagenCabecera {
        height: 500px;
        background-position: 50% 50%;
        border-radius:  0 0 50% 50% / 130px;
        position: relative;
        text-align: center;
        z-index: 10;
    }

    .imagenCabecera.homeContainer {
        opacity: 0.5;
    }

    .imagenCabecera h3 {
        color: #fff;
        font-size: 2.5em;
        display:inline-block;
        margin-top: 200px;
        font-weight: 600;
    }

    .imagenCabecera h3.home{
        margin-top: 150px;
    }

    .imagenCabecera p{
        color:#fff;
        font-size: 1.6em;
    }
</style>