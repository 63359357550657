import axios from 'axios'
import {
    RCDCREATEEMISIONESATMOSFERICAS,
    RCDGETEMISIONESATMOSFERICASBYID,
    RCDEDITEMISIONESATMOSFERICAS,
    RCDCREATEMATERIAPRIMA,
    RCDGETMATERIAPRIMABYID,
    RCDEDITMATERIAPRIMA,
    RCDCREATEINFRASERVICIO,
    RCDGETINFRASERVICIOBYID,
    RCDEDITINFRASERVICIO,
    RCDCREATEENERGIAELECTRICA,
    RCDGETENERGIAELECTRICABYID,
    RCDEDITENERGIAELECTRICA,
    RCDCREATEVERTIMIENTO,
    RCDGETVERTIMIENTOBYID,
    RCDEDITVERTIMIENTO,
    RCDCREATERESIDSOLIDOSVC,
    RCDGETRESIDSOLIDOSVCBYID,
    RCDEDITRESIDSOLIDOSVC,
    RCDCREATERECURSOSNATURALES,
    RCDGETRECURSOSNATURALESBYID,
    RCDEDITRECURSOSNATURALES,
    RCDCREATELISTACHEQUEO,
    RCDGETLISTACHEQUEOBYID,
    RCDEDITLISTACHEQUEO,
} from '../../constantes'

export default {
    namespaced: true,
    state: {
        emisionesAtmosfericasCargada: {},
        resultEmisionesAtmosfericas: null,

        materiasPrimasCargada: {},
        resultMateriasPrimas: null,

        infraServiCargada: {},
        resultInfraServi: null,

        energiaElectricaCargada: {},
        resultEnergiaElectrica: null,

        vertimientoCargado: {},
        resultVertimiento: null,

        residuosSolidosVCCargado: {},
        resultResiduosSolidosVC: null,

        recursosNaturalesCargados: {},
        resultRecursosNaturales: null,
        
        listaChequeoCargada: {},
        resultListaChequeo: null,
    },
    mutations: {
        // EMISIONES ATOMOSFERICAS
        setResultCreateEmisionesAtmosfericas(state, payload){
            state.resultEmisionesAtmosfericas = payload
        },
        setEmisionesAtmosfericasCargadas(state, payload){
            state.emisionesAtmosfericasCargada = payload
        },
        setResultEditEmisionesAtmosfericas(state, payload){
            state.resultEmisionesAtmosfericas = payload
        },

        // MATERIA PRIMA
        setResultCreateMateriasPrimas(state, payload){
            state.resultMateriasPrimas = payload
        },
        setMateriasPrimasCargadas(state, payload){
            state.materiasPrimasCargada = payload
        },
        setResultEditMateriasPrimas(state, payload){
            state.resultMateriasPrimas = payload
        },
        
        // INFRA SERVI
        setResultCreateInfraServi(state, payload){
            state.resultInfraServi = payload
        },
        setInfraServiCargadas(state, payload){
            state.infraServiCargada = payload
        },
        setResultEditInfraServi(state, payload){
            state.resultInfraServi = payload
        },
       
        // ENERGIA ELECTRICA
        setResultCreateEnergiaElectrica(state, payload){
            state.resultEnergiaElectrica = payload
        },
        setEnergiaElectricaCargadas(state, payload){
            state.energiaElectricaCargada = payload
        },
        setResultEditEnergiaElectrica(state, payload){
            state.resultEnergiaElectrica = payload
        },

        // VERTIMIENTO
        setResultCreateVertimiento(state, payload){
            state.resultVertimiento = payload
        },
        setVertimientoCargadas(state, payload){
            state.vertimientoCargado = payload
        },
        setResultEditVertimiento(state, payload){
            state.resultVertimiento = payload
        },

        // RESIDUOS SOIDOS VC
        setResultCreateResiduosSolidosVC(state, payload){
            state.resultResiduosSolidosVC = payload
        },
        setResiduosSolidosVCCargados(state, payload){
            state.residuosSolidosVCCargado = payload
        },
        setResultEditResiduosSolidosVC(state, payload){
            state.resultResiduosSolidosVC = payload
        },

        // RECURSOS NATURALES
        setResultCreateRecursosNaturales(state, payload){
            state.resultRecursosNaturales = payload
        },
        setRecursosNaturalesCargados(state, payload){
            state.recursosNaturalesCargados = payload
        },
        setResultEditRecursosNaturales(state, payload){
            state.resultRecursosNaturales = payload
        },

        // LISTA CHEQUEO
        setResultCreateListaChequeo(state, payload){
            state.resultListaChequeo = payload
        },
        setListaChequeoCargadas(state, payload){
            state.listaChequeoCargada = payload
        },
        setResultEditListaChequeo(state, payload){
            state.resultListaChequeo = payload
        },
    },
    actions: {
        // EMISIONES ATMOSFERICAS
        async crearEmisionesAtmosfericas({commit}, emisioneAtmosfericas){
            return axios.post(RCDCREATEEMISIONESATMOSFERICAS, emisioneAtmosfericas)
                .then(results=>{
                    commit('setResultCreateEmisionesAtmosfericas', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async cargarEmisionesAtmosfericasById({commit}, id){
            return axios.put(RCDGETEMISIONESATMOSFERICASBYID+id)
                .then(results=>{
                    commit('setEmisionesAtmosfericasCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async editarEmisionesAtmosfericas({commit}, emisioneAtmosfericas){
            return axios.post(RCDEDITEMISIONESATMOSFERICAS, emisioneAtmosfericas)
                .then(results=>{
                    commit('setResultEditEmisionesAtmosfericas', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        // MATERIA PRIMA
        async crearMateriaPrima({commit}, materiaPrima){
            return axios.post(RCDCREATEMATERIAPRIMA, materiaPrima)
                .then(results=>{
                    commit('setResultCreateMateriasPrimas', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async cargarMateriaPrimaById({commit}, id){
            return axios.put(RCDGETMATERIAPRIMABYID+id)
                .then(results=>{
                    commit('setMateriasPrimasCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async editarMateriaPrima({commit}, materiaPrima){
            return axios.post(RCDEDITMATERIAPRIMA, materiaPrima)
                .then(results=>{
                    commit('setResultEditMateriasPrimas', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        // INFRA SERVI
        async crearInfraServi({commit}, materiaPrima){
            return axios.post(RCDCREATEINFRASERVICIO, materiaPrima)
                .then(results=>{
                    commit('setResultCreateInfraServi', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async cargarInfraServiById({commit}, id){
            return axios.put(RCDGETINFRASERVICIOBYID+id)
                .then(results=>{
                    commit('setInfraServiCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async editarInfraServi({commit}, materiaPrima){
            return axios.post(RCDEDITINFRASERVICIO, materiaPrima)
                .then(results=>{
                    commit('setResultEditInfraServi', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        // ENERGIA ELECTRICA
        async crearEnergiaElectrica({commit}, energiaElectrica){
            return axios.post(RCDCREATEENERGIAELECTRICA, energiaElectrica)
                .then(results=>{
                    commit('setResultCreateEnergiaElectrica', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async cargarEnergiaElectricaById({commit}, id){
            return axios.put(RCDGETENERGIAELECTRICABYID+id)
                .then(results=>{
                    commit('setEnergiaElectricaCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async editarEnergiaElectrica({commit}, energiaElectrica){
            return axios.post(RCDEDITENERGIAELECTRICA, energiaElectrica)
                .then(results=>{
                    commit('setResultEditEnergiaElectrica', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        // VERTIMIENTO
        async crearVertimiento({commit}, vertimiento){
            return axios.post(RCDCREATEVERTIMIENTO, vertimiento)
                .then(results=>{
                    commit('setResultCreateVertimiento', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async cargarVertimientoById({commit}, id){
            return axios.put(RCDGETVERTIMIENTOBYID+id)
                .then(results=>{
                    commit('setVertimientoCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async editarVertimiento({commit}, vertimiento){
            return axios.post(RCDEDITVERTIMIENTO, vertimiento)
                .then(results=>{
                    commit('setResultEditVertimiento', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        // RESIDUOS SOLIDOS
        async crearResiduosSolidosVC({commit}, residuosSolidosVC){
            return axios.post(RCDCREATERESIDSOLIDOSVC, residuosSolidosVC)
                .then(results=>{
                    commit('setResultCreateResiduosSolidosVC', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async cargarResiduosSolidosVCById({commit}, id){
            return axios.put(RCDGETRESIDSOLIDOSVCBYID+id)
                .then(results=>{
                    commit('setResiduosSolidosVCCargados', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async editarResiduosSolidosVC({commit}, residuosSolidosVC){
            return axios.post(RCDEDITRESIDSOLIDOSVC, residuosSolidosVC)
                .then(results=>{
                    commit('setResultEditResiduosSolidosVC', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        // RECURSOS NATURALES
        async crearRecursosNaturales({commit}, recursosNaturales){
            return axios.post(RCDCREATERECURSOSNATURALES, recursosNaturales)
                .then(results=>{
                    commit('setResultCreateRecursosNaturales', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async cargarRecursosNaturalesById({commit}, id){
            return axios.put(RCDGETRECURSOSNATURALESBYID+id)
                .then(results=>{
                    commit('setRecursosNaturalesCargados', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async editarRecursosNaturales({commit}, recursosNaturales){
            return axios.post(RCDEDITRECURSOSNATURALES, recursosNaturales)
                .then(results=>{
                    commit('setResultEditRecursosNaturales', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        // LISTA CHEQUEO
        async crearListaChequeo({commit}, recursosNaturales){
            return axios.post(RCDCREATELISTACHEQUEO, recursosNaturales)
                .then(results=>{
                    commit('setResultCreateListaChequeo', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async cargarListaChequeoById({commit}, id){
            return axios.put(RCDGETLISTACHEQUEOBYID+id)
                .then(results=>{
                    commit('setListaChequeoCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        async editarListaChequeo({commit}, recursosNaturales){
            return axios.post(RCDEDITLISTACHEQUEO, recursosNaturales)
                .then(results=>{
                    commit('setResultEditListaChequeo', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
    },

}