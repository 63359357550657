import axios from 'axios';
import {
    RCDGETEMPRESAS,
    RCDGETACTIVIDADESECONOMICAS,
    RCDCREATEEMPRESAS,
    RCDBUSCAREMPRESAS,
    RCDDELETEMPRESAS,
    RCDEDITEMPRESAS,
    RCDGETPROVEEDORES
} from '../../constantes';

export default {
    namespaced:true,
    state: {
        proveedoresServicio: [],
        empresasServicio: [],
        empresasServicioBuscar: [],
        empresaSeleccionada: {},
        mostrarModalBuscarBoolean: false,
        actividadesEconomicas:[],
        resultCrearEmpresa: null,
        resultEliminarEmpresa: null,
    },
    mutations:{
        setProveedores(state, payload){
            state.proveedoresServicio = payload
        },
        setEmpresas(state, payload){
            state.empresasServicio = payload
        },
        setEmpresasBusqueda(state, payload){
            state.empresasServicioBuscar = payload
        },
        resetEmpresasBuscar(state){
            state.empresasServicioBuscar = []
        },
        setActividadesEconomicas(state, payload){
            state.actividadesEconomicas = payload
        },
        setEmpresaSeleccionada(state, payload){
            state.empresaSeleccionada = payload
        },
        setMostrarModalBuscar(state,payload){
            state.mostrarModalBuscarBoolean = payload
        },
        setResultCrearEmpresa(state, payload){
            state.resultCrearEmpresa = payload
        },
        setResultEliminarEmpresa(state, payload){
            state.resultEliminarEmpresa = payload
        }
    },
    actions:{
        async cargarProveedores({commit}){
            return axios.get(RCDGETPROVEEDORES)
                .then(results=>{
                    commit('setProveedores', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async cargarEmpresas({commit}){
            return axios.get(RCDGETEMPRESAS)
                .then(results=>{
                    commit('setEmpresas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        async cargarActividadesEconomicas({commit}){
            return axios.get(RCDGETACTIVIDADESECONOMICAS)
                .then(results=>{
                    commit('setActividadesEconomicas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        async crearEmpresa({commit}, empresa){
            return axios.post(RCDCREATEEMPRESAS, empresa)
                .then(results=>{
                    commit('setResultCrearEmpresa', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        asignarEmpresaSeleccionada({commit}, empresa){
            commit('setEmpresaSeleccionada', empresa)
        },

        MostrarModalBuscar({commit}, value){
            console.log('aqui')
            commit('setMostrarModalBuscar', value)
        },

        resetEmpresasBuscar({commit}){
            commit('resetEmpresasBuscar')
        },

        async BuscarEmpresas({commit}, filtros){
            return axios.post(RCDBUSCAREMPRESAS, filtros)
                .then(results=>{
                    commit('setEmpresasBusqueda', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        async eliminarEmpresa({commit}, id){
            return axios.put(RCDDELETEMPRESAS+id)
                .then(results=>{
                    commit('setResultEliminarEmpresa', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        async editarEmpresa({commit}, empresa){
            return axios.post(RCDEDITEMPRESAS, empresa)
                .then(results=>{
                    commit('setResultCrearEmpresa', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
    },
}