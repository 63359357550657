import axios from 'axios';
import {
    RCDGETCANTVISITASESTADOFECHA, 
    RCDGETCANTVISESTFECHABYNUMACTA, 
    RCDGETVISITASBYDATE,
    RCDGETCANTVISESTFECHABYLICENCIA,
    RCDEDITVISITA,
    RCDCREATERESSOLLICENCIA,
    RCDEDITRESSOLLICENCIA,
    RCDGETRESSOLLICENCIABYID,
    RCDGETCIERREVISITABYID,
    RCDCREATECIERREVISITA,
    RCDEDITCIERREVISITA,
    RCDGENERARPDFRCD,
    RCDGENERARPDFVYC,
    RCDGETCANTVISITASESTADOFECHAEMPRESA,
    RCDGETVISITASBYDATEEMPRESA,
    RCDGETCANTVISESTFECHABYEMPRESA
} from '../constantes'

export default {
    namespaced:true,
    state: {
        visitasCargadas: [],
        visitasPorDia: [],
        visitaEnGestion: {},
        resultUpdateVisita: null,
        resultCreateResSol: null,
        residuosSolidosCargado: {},
        cierreVisitaCargada: {},
        resultCierreVisita:null,
        verDetalles: false,
        resultPDF: {},
        resultPDFVYC: {},
    },
    mutations:{
        setVerDetalles(state, payload){
            state.verDetalles = payload
        },

        setVisitasCargadas(state, payload){
            state.visitasCargadas = payload
        },
        setVisitasPorDia(state, payload){
            state.visitasPorDia = payload
        },
        setVisitaEnGestion(state, payload){
            state.visitaEnGestion = payload
        },
        setResultUpdate(state, payload){
            state.resultUpdateVisita = payload
        },
        setResultPDF(state, payload){
            state.resultPDF = payload
        },
        setResultPDFVYC(state, payload){
            state.resultPDFVYC = payload
        },

        // RESIDUOS SOLIDOS
        setResultCreateResSolidos(state, payload){
            state.resultCreateResSol = payload
        },
        setResiduosSolidosCargado(state, payload){
            state.residuosSolidosCargado = payload
        },
        setResultEditResiduosSolidos(state, payload){
            state.resultCreateResSol=payload
        },
       
        // CIERRE VISITA
        setResultCreateCierreVisita(state, payload){
            state.resultCierreVisita = payload
        },
        setCierreVisitaCargada(state, payload){
            state.cierreVisitaCargada = payload
        },
        setResultEditCierreVisita(state, payload){
            state.resultCierreVisita=payload
        }
    },
    actions: {
        activarVerDetalle({commit}, activo){
            commit('setVerDetalles', activo)
        },

       async  cargarVisitasProgramadas({commit}){
            return axios.get(RCDGETCANTVISITASESTADOFECHA)
                .then(results=>{
                    commit('setVisitasCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
       
        async  cargarVisitasProgramadasVigilanciaControl({commit}){
            return axios.get(RCDGETCANTVISITASESTADOFECHAEMPRESA)
                .then(results=>{
                    commit('setVisitasCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async buscarVisitasNumActa({commit}, numActa){
            return axios.put(RCDGETCANTVISESTFECHABYNUMACTA+numActa)
                .then(results=>{
                    commit('setVisitasCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        async buscarVisitasLicencia({commit}, licencia){
            return axios.put(RCDGETCANTVISESTFECHABYLICENCIA+licencia)
                .then(results=>{
                    commit('setVisitasCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async cargarVisitasPorDia({commit}, date){
            return axios.put(RCDGETVISITASBYDATE+date)
                .then(results=>{
                    commit('setVisitasPorDia', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        async cargarVisitasPorDiaVigilanciaControl({commit}, date){
            return axios.put(RCDGETVISITASBYDATEEMPRESA+date)
                .then(results=>{
                    commit('setVisitasPorDia', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async buscarVisitasVigilanciaControlByEmpresaId({commit}, form){
            return axios.post(RCDGETCANTVISESTFECHABYEMPRESA, form)
                .then(results=>{
                    commit('setVisitasCargadas', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
       
        guardarVisitaEnGestion({commit}, visita){
            commit('setVisitaEnGestion', visita)
        },

        // --------------------- TABS 

        // ----------------------- TABS COMUNES

        // DATOSBASICOS COMUN ENTRE RCD / VIGILANCIA Y CONTROL
        
        async actualizarDatosBasicos({commit}, datosBasicos){
            return axios.post(RCDEDITVISITA, datosBasicos)
                .then(results=>{
                    commit('setResultUpdate', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async descargarPDF({commit}, idVisita){
            return axios.put(RCDGENERARPDFRCD+idVisita)
                .then(results=>{
                    commit('setResultPDF', results.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async descargarPDFVYC({commit}, idVisita){
            return axios.put(RCDGENERARPDFVYC+idVisita)
                .then(results=>{
                    commit('setResultPDFVYC', results.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

         // CIERRE VISITA
         async crearCierreVisita({commit}, cierreVisita){
            return axios.post(RCDCREATECIERREVISITA, cierreVisita)
                .then(results=>{
                    commit('setResultCreateCierreVisita', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        async cargarCierreVisitaById({commit}, id){
            return axios.put(RCDGETCIERREVISITABYID+id)
                .then(results=>{
                    commit('setCierreVisitaCargada', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async editarCierreVisita({commit}, cierreVisita){
            return axios.post(RCDEDITCIERREVISITA, cierreVisita)
                .then(results=>{
                    commit('setResultEditCierreVisita', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        // ------------------------------- TABS RCD

        // RESIDUOS SOLIDOS RCD
        async crearResiduosSolidos({commit}, residuosSolidos){
            return axios.post(RCDCREATERESSOLLICENCIA, residuosSolidos)
                .then(results=>{
                    commit('setResultCreateResSolidos', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },
        
        async cargarResiduosSolidosById({commit}, id){
            return axios.put(RCDGETRESSOLLICENCIABYID+id)
                .then(results=>{
                    commit('setResiduosSolidosCargado', results.data.data)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        async editarResiduosSolidos({commit}, residuosSolidos){
            return axios.post(RCDEDITRESSOLLICENCIA, residuosSolidos)
                .then(results=>{
                    commit('setResultEditResiduosSolidos', results)
                })
                .catch(error=>{
                    if (error.response) {
                        return Promise.reject(error.response)
                    }
                })
        },

        // ------------------------------- TABS VIGILANCIA CONTROL CONTINUAN EN SU PROPIO STORE --------------------------------
        // gestionVisitaVigilanciaControl.js     
    }
}