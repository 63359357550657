import axios from 'axios';
import {
    RCDGETVISITASBYLICENCIAID, 
    RCDCREATEVISITA, 
    RCDDELETEVISITA,
    RCDGETVISITASBYEMPRESAID
} from '../constantes'

export default {
    namespaced:true,
    state: {
        visitasCargadas: [],
        datosGuardados: {},
        resEliminar: {},
    },
    mutations:{
        setVisitasCargadas(state, payload){
            state.visitasCargadas = payload
        },
        setDatosGuardar(state,payload){
            state.datosAGuardar = payload
        },
        setResEliminar(state,payload){
            state.resEliminar = payload
        }
    },
    actions: {
       async  buscarVisitasLicenciaSeleccionada({commit}, idLicencia){
            return axios.put(RCDGETVISITASBYLICENCIAID+idLicencia)
                .then(results=>{
                    console.log(results)
                    commit('setVisitasCargadas', results.data.data)
                })
               .catch(error=>{return Promise.reject(error.response)})
        },
        async guardarVisita({commit}, datosGuardar){
            return axios.post(RCDCREATEVISITA, datosGuardar)
                .then(results=>{
                    commit('setDatosGuardar', results)
                })
               .catch(error=>{return Promise.reject(error.response)})
        },
        async eliminarVisitaId({commit}, idVisita){
            return axios.put(RCDDELETEVISITA+idVisita)
                .then(results=>{
                    commit('setResEliminar', results.data.data)
                })
                .catch(error=>{return Promise.reject(error.response)})
        },

        // VIGILANCIA Y CONTROL

        async  buscarVisitasEmpresaSeleccionada({commit}, idEmpresa){
            return axios.put(RCDGETVISITASBYEMPRESAID+idEmpresa)
                .then(results=>{
                    console.log(results)
                    commit('setVisitasCargadas', results.data.data)
                })
               .catch(error=>{return Promise.reject(error.response)})
        },

        resetVisitasEmpresaSeleccionada({commit}){
            commit('setVisitasCargadas', [])
        }
    }
}